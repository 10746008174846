import React from 'react';
import PropTypes from 'prop-types';
import { Text, FlexLayout } from '@deity/falcon-ui';
import { Image } from '../Image';
import { ProductPrice, ProductPriceProps } from './ProductPrice';
import { ProductCardLayout } from './ProductCardLayout';

export type ProductCardProps = {
  name: string;
  urlPath: string;
  price: ProductPriceProps;
  thumbnail?: string;
};
export const ProductCard: React.SFC<ProductCardProps> = ({ name, thumbnail, urlPath, price }) => {
  return (
    <ProductCardLayout to={urlPath}>
      {thumbnail && (
        <Image
          css={{ height: '100%', width: '100%', flex: '1 1 100%', minHeight: '0%' }}
          src={thumbnail}
          alt={name}
          loading="lazy"
        />
      )}
      <Text py="xs" ellipsis>
        {name}
      </Text>
      <FlexLayout>
        <ProductPrice {...price} fontSize="md" />
      </FlexLayout>
    </ProductCardLayout>
  );
};
ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  // @ts-ignore https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34692
  price: PropTypes.shape({
    regular: PropTypes.number.isRequired,
    special: PropTypes.number,
    min: PropTypes.number
  }).isRequired,
  thumbnail: PropTypes.string
};
