import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';
import { GET_CART } from './CartQuery';

export const EMPTY_CART = gql`
  mutation EmptyCart {
    emptyCart
  }
`;

export type EmptyCartResponse = { emptyCart: boolean };

const defaultOptions = {
  refetchQueries: ['Cart', 'MiniCart'],
  awaitRefetchQueries: false
};

export class EmptyCartMutation extends Mutation<EmptyCartResponse> {
  static defaultProps = {
    mutation: EMPTY_CART,
    ...defaultOptions
  };
}

export const useEmptyCartMutation = (options: MutationHookOptions<EmptyCartResponse> = {}) =>
  useMutation(EMPTY_CART, {
    ...defaultOptions,
    ...options
  });
