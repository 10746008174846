import { useState, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { usePrevious } from './usePrevious';

/**
 * React hook for keeping component state like `useState`,
 * additionally expose `callback` when state is changed
 * @param initialState
 * @param callback
 */
export const useStateCallback = <S>(
  initialState: S | (() => S),
  callback: (state: S, previousState?: S) => void
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState(initialState);
  const previousState = usePrevious(state);

  useLayoutEffect(() => {
    if (state !== previousState) {
      callback(state, previousState);
    }
  }, [state, callback, previousState]);

  return [state, setState];
};
