import { useEffect, useState, useRef } from 'react';

export type UseIntersectionObserverProps = {
  root?: Element;
  rootMargin?: string;
  threshold?: number | number[];
};

/**
 * React hook wrapper for `window` `IntersectionObserver` api
 * @param props
 */
export const useIntersectionObserver = ({
  root = null,
  rootMargin,
  threshold = 0
}: UseIntersectionObserverProps): [React.Dispatch<React.SetStateAction<Element>>, IntersectionObserverEntry] => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>({} as any);
  const [node, setNode] = useState<Element>(null);

  const observer = useRef(
    new window.IntersectionObserver(entries => setEntry(entries[0]), {
      root,
      rootMargin,
      threshold
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};

export const canUseIntesectionObserver = (): boolean => {
  return (
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window?.IntersectionObserverEntry?.prototype &&
    'isIntersecting' in window?.IntersectionObserverEntry?.prototype
  );
};
