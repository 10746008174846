import React from 'react';
import { Label, Text, Radio } from '@deity/falcon-ui';

export type RadioInputProps = { label: string } & Parameters<typeof Radio>[0];
export const RadioInput: React.FC<RadioInputProps> = React.memo(({ children, label, ...rest }) => {
  return (
    <Label htmlFor={rest.id} css={{ display: 'inline-flex', alignItems: 'center' }}>
      <Radio {...rest} />
      {children || (
        <Text as="span" p="xs">
          {label}
        </Text>
      )}
    </Label>
  );
});
