import React from 'react';
import { Box, Label } from '@deity/falcon-ui';
import { Field, requiredValidator } from '@deity/falcon-front-kit';
import { FormFieldError } from '../Forms';
import { ProductOptionRadio } from './ProductOptionRadio';

export type ProductOption = {
  id: string;
  required: boolean;
  label?: string;
  values: ProductOptionValue[];
};
export type ProductOptionValue = {
  isDefault: string;
  label: string;
  id: string;
};

export type ProductOptionRadioGroupFieldProps = {
  label?: string;
  name: string;
  values: ProductOptionValue[];
  disabled?: boolean;
};
export const ProductOptionRadioGroupField: React.SFC<ProductOptionRadioGroupFieldProps> = ({
  label,
  name,
  values,
  disabled
}) => (
  <Field name={name} label={label} validate={[requiredValidator]}>
    {({ field, error }) => {
      return (
        <Box as="fieldset" p="none" border="none">
          {label && (
            <Box as="legend" mb="xs">
              {label}
            </Box>
          )}
          {values.map(value => (
            <Label htmlFor={`${field.id}-${value.id}`} key={value.id} mr="xs" style={{ display: 'inline-flex' }}>
              <ProductOptionRadio
                {...field}
                id={`${field.id}-${value.id}`}
                value={value.id}
                checked={value.id === field.value}
                disabled={disabled}
                icon={<div>{value.label}</div>}
              />
            </Label>
          ))}
          <FormFieldError>{field.invalid ? error : null}</FormFieldError>
        </Box>
      );
    }}
  </Field>
);
