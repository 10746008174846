import { useThrowError, ErrorModel, tryGetUserError } from '../Error';

export type ErrorModelArray = Array<ErrorModel> & {
  getFieldErrors: () => Record<string, ErrorModel>;
  getFormErrors: () => ErrorModel[];
};

/**
 * React Hook which returns only user errors, and re-throw if any `ApolloError.NetworkError` or unhandled error exist
 */
export function useGetUserError(): (error: Error) => ErrorModelArray {
  const throwError = useThrowError();

  /**
   * returns only user errors, and re-throw if any `ApolloError.NetworkError` or unhandled error exist
   * @param {Error} error
   */
  function get<TError extends Error>(error: TError): ErrorModelArray {
    const userErrors = tryGetUserError(error);

    if (userErrors.length === 0) {
      throwError(error);
    }
    const result: any = [...userErrors];
    result.getFieldErrors = () =>
      userErrors.filter(x => x.path).reduce((prev, x) => ({ ...prev, [x.path]: x.message }), {});
    result.getFormErrors = () => userErrors.filter(x => !x.path);

    return result;
  }

  return get;
}
