const codes = {
  GRAPHQL_PARSE_FAILED: null,
  GRAPHQL_VALIDATION_FAILED: null,
  INTERNAL_SERVER_ERROR: null,
  UNAUTHENTICATED: null,
  FORBIDDEN: null,
  NOT_FOUND: null,
  CUSTOMER_TOKEN_NOT_FOUND: null,
  CUSTOMER_TOKEN_EXPIRED: null,
  SESSION_NOT_FOUND: null,
  BAD_USER_INPUT: null
};

Object.keys(codes).forEach(key => {
  codes[key] = key;
});

export { codes };
