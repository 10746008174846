import React, { useMemo } from 'react';
import { useI18n } from '@deity/falcon-i18n';
import { getCurrencyLabel, useLocale } from '@deity/falcon-front-kit';
import { Box, Label, themed } from '@deity/falcon-ui';
import { SelectInput, SelectInputProps } from '../Forms';

export type CurrencyPickerProps = Omit<SelectInputProps, 'options' | 'onChange'> & {
  options: [string];
  value: string;
  showCartWarning: boolean;
  id: string;
  label: string;
  onChange: (value: string) => any;
};

export const CurrencyPickerLayout = themed({
  tag: Box,
  defaultTheme: {
    currencyPickerLayout: {
      css: {
        minWidth: 96
      }
    }
  }
});

export const CurrencyPicker: React.SFC<CurrencyPickerProps> = ({
  value,
  options,
  onChange,
  showCartWarning,
  id,
  label,
  ...rest
}) => {
  const { locale, localeFallback } = useLocale();
  const formattedOptions = options.map(option => ({
    optionValue: option,
    optionLabel: getCurrencyLabel(option, locale || localeFallback)
  }));
  const selectOptions = useMemo(
    () =>
      formattedOptions.map(({ optionValue, optionLabel }) => ({
        value: optionValue,
        label: optionLabel
      })),
    [formattedOptions]
  );

  const { t } = useI18n();

  return (
    <CurrencyPickerLayout>
      {label && <Label htmlFor={id ? `currency-switcher-${id}` : 'currency-switcher'}>{label}</Label>}
      <SelectInput
        {...rest}
        id={id ? `currency-switcher-${id}` : 'currency-switcher'}
        value={value}
        options={selectOptions}
        aria-label={label || t('currency.label')}
        onChange={e => {
          const confirmation = showCartWarning ? confirm(t('currency.changeNote')) : true;
          if (confirmation === true) {
            onChange(e.target.value);
          }
        }}
      />
    </CurrencyPickerLayout>
  );
};
