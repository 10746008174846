import { CSSObject, Theme } from '@deity/falcon-ui';

export const toGridTemplate = (items: string[][]): string => {
  const columnTemplate = items.shift();
  if (!columnTemplate) {
    return '';
  }

  const gridAreas = items
    .map(item => {
      const rowTemplate = item.length > columnTemplate.length ? item.pop() : '';
      return `"${item.join(' ')}" ${rowTemplate}`;
    })
    .join(' ');

  return `${gridAreas} / ${columnTemplate.join(' ')}`;
};

export const prettyScrollbars = (theme: Theme): CSSObject => ({
  overflowY: ['auto', 'overlay'] as any,
  WebkitOverflowScrolling: 'touch',
  paddingRight: 10,
  marginRight: 6,
  '::-webkit-scrollbar': {
    width: 3,
    backgroundColor: theme.colors.secondaryLight,
    borderRadius: theme.borderRadius.medium
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.secondaryDark
  }
});

export const noScrollbars = (): CSSObject => ({
  overflow: ['auto', 'overlay'],
  WebkitOverflowScrolling: 'touch',
  '::-webkit-scrollbar': {
    display: 'none'
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none'
});

export const lineClamp = (numLines: number): CSSObject => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  WebkitLineClamp: numLines
});

export const placeholder = (styles: CSSObject): CSSObject => ({
  '::-webkit-input-placeholder': styles,
  '::-moz-placeholder': styles,
  ':-ms-input-placeholder`': styles,
  '::-ms-input-placeholder`': styles
});
