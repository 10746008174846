import { ProductOption, ProductOptionInput } from '@deity/falcon-shop-extension';

export type ProductOptionsMap = {
  [id: string]: any;
};

export const productOptionsToForm = (
  options: (Pick<ProductOption, 'id'> & { defaultValue?: any; values?: { id: any; isDefault: boolean }[] })[]
): ProductOptionsMap =>
  options.reduce((result, option) => {
    const values = Array.isArray(option.values) && option.values.length ? option.values : [];
    const defaultValue = values.find(x => x.isDefault);

    return {
      ...result,
      [option.id]: option.defaultValue || (defaultValue && defaultValue.id) || undefined
    };
  }, {});

export const formProductOptionsToInput = (options: ProductOptionsMap) =>
  Object.entries(options)
    .filter(x => x[1] !== undefined)
    .map<ProductOptionInput>(item => ({
      id: parseInt(item[0], 10),
      value: item[1]
    }));
