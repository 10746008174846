import gql from 'graphql-tag';
import { Mutation, OperationInput } from '@deity/falcon-data';
import { CouponInput } from '@deity/falcon-shop-extension';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';

export const CANCEL_COUPON = gql`
  mutation CancelCoupon($input: CouponInput!) {
    cancelCoupon(input: $input)
  }
`;

export type CancelCouponResult = {
  cancelCoupon: boolean;
};

const defaultOptions = {
  mutation: CANCEL_COUPON,
  refetchQueries: ['Cart'],
  awaitRefetchQueries: true
};

export class CancelCouponMutation extends Mutation<CancelCouponResult, OperationInput<CouponInput>> {
  static defaultProps = defaultOptions;
}

export const useCancelCouponMutation = (
  options: MutationHookOptions<CancelCouponResult, OperationInput<CouponInput>> = {}
) =>
  useMutation(defaultOptions.mutation, {
    ...defaultOptions,
    ...options
  });
