import gql from 'graphql-tag';
import { useQuery, useLazyQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/react-hooks';
import { Query, OperationInput } from '@deity/falcon-data';
import { ProductDetailsInput, ProductDetails } from '@deity/falcon-shop-extension';

export const GET_PRODUCT_DETAILS_FROM_OPTIONS = gql`
  query ProductDetailsFromOptions($input: ProductDetailsInput!) {
    productDetailsFromOptions(input: $input) {
      id
      sku
      gallery {
        full
        thumbnail
        isDefault
      }
      price {
        regular
        special
        minTier
      }
    }
  }
`;

export type ProductDetailsFromOptionsResponse = {
  productDetailsFromOptions: ProductDetails;
};

export class ProductDetailsFromOptionsQuery extends Query<
  ProductDetailsFromOptionsResponse,
  OperationInput<ProductDetailsInput>
> {
  static defaultProps = {
    query: GET_PRODUCT_DETAILS_FROM_OPTIONS,
    fetchPolicy: 'cache-and-network'
  };
}

export const useProductDetailsFromOptionsQuery = (
  options: QueryHookOptions<ProductDetailsFromOptionsResponse, OperationInput<ProductDetailsInput>> = {}
) =>
  useQuery(GET_PRODUCT_DETAILS_FROM_OPTIONS, {
    fetchPolicy: 'cache-and-network',
    ...options
  });

export const useProductDetailsFromOptionsLazyQuery = (
  options: LazyQueryHookOptions<ProductDetailsFromOptionsResponse, OperationInput<ProductDetailsInput>> = {}
) =>
  useLazyQuery(GET_PRODUCT_DETAILS_FROM_OPTIONS, {
    fetchPolicy: 'cache-and-network',
    ...options
  });
