import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';
import { Product as ShopProduct, GalleryEntry, ProductOption } from '@deity/falcon-shop-extension';

export const GET_PRODUCT = gql`
  query Product($id: ID!, $path: String!) {
    product(id: $id) {
      id
      sku
      name
      description
      price {
        regular
        special
        minTier
      }
      tierPrices {
        qty
        value
        discount
      }
      currency
      gallery {
        full
        thumbnail
        isDefault
      }
      options {
        __typename
        ...ProductOption

        ... on ProductDropdownOption {
          values {
            ...ProductOptionValue
            label
          }
        }

        ... on ProductCheckboxOption {
          checkboxLabel
          values {
            ...ProductOptionValue
            checkboxValue: value
          }
        }

        ... on ProductRadioOption {
          values {
            ...ProductOptionValue
            label
          }
        }

        ... on ProductRectangleOption {
          values {
            ...ProductOptionValue
            label
          }
        }

        ... on ProductSwatchOption {
          values {
            ...ProductOptionValue
            label
            swatchValues: value
            type
          }
        }

        ... on ProductPickListOption {
          values {
            ...ProductOptionValue
            label
            product: value {
              ...PickListProductFragment
            }
          }
        }

        ... on ProductTextOption {
          defaultStringValue
          minLength
          maxLength
        }

        ... on ProductNumberOption {
          defaultIntValue
          minValue
          maxValue
          integersOnly
          isRange
        }

        ... on ProductTextAreaOption {
          defaultStringValue
          minLength
          maxLength
          maxLines
        }

        ... on ProductDateOption {
          defaultDate
          minDate
          maxDate
          isRange
        }

        ... on ProductFileOption {
          supportedTypes
          maxSize
          mode
        }
      }
      seo {
        title
        description
        keywords
      }
      breadcrumbs(path: $path) {
        name
        urlPath
      }
    }
  }

  fragment ProductOption on ProductOption {
    id
    required
    label
  }

  fragment ProductOptionValue on ProductOptionValue {
    id
    isDefault
  }

  fragment PickListProductFragment on Product {
    name
    image
  }
`;

export type Product = Pick<
  ShopProduct,
  'id' | 'sku' | 'name' | 'description' | 'price' | 'tierPrices' | 'currency' | 'seo' | 'breadcrumbs'
> & {
  gallery: Pick<GalleryEntry, 'full' | 'thumbnail' | 'isDefault'>[];
  options: ProductOption[];
};

export type ProductResponse = {
  product: Product;
};

export class ProductQuery extends Query<ProductResponse> {
  static defaultProps = {
    query: GET_PRODUCT,
    fetchPolicy: 'cache-and-network'
  };
}
