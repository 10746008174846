import React, { useState } from 'react';
import { Details } from '@deity/falcon-ui';

export type FilterDetailsRenderProps = {
  on: boolean;
  toggle: (e?: React.MouseEvent<HTMLElement>) => void;
  set: React.Dispatch<React.SetStateAction<boolean>>;
};

export type FilterDetailsProps = {
  initiallyOpen?: boolean;
  children: (value: FilterDetailsRenderProps) => React.ReactNode;
};

export const FilterDetails: React.SFC<FilterDetailsProps> = ({ initiallyOpen, children, ...rest }) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  return (
    <Details
      open={isOpen}
      {...rest}
      defaultTheme={{
        filterDetails: {
          display: 'flex',
          flexDirection: 'column',

          css: (props: any) => ({
            '> :not(summary, style)': {
              display: props.open ? 'block' : 'none',
              flex: props.open ? '1' : 0
            },

            '> summary::-webkit-details-marker': {
              display: 'none'
            },

            '> summary:after': {
              display: 'block',
              content: props.open ? '"-"' : '"+"',
              marginLeft: props.theme.spacing.sm,
              fontSize: props.theme.fontSizes.md,
              lineHeight: 0.6,
              fontWeight: props.theme.fontWeights.bold,
              color: props.theme.colors.primary
            }
          })
        }
      }}
    >
      {children({
        set: setIsOpen,
        on: isOpen,
        toggle: e => {
          if (e) {
            e.preventDefault();
          }
          setIsOpen(prev => !prev);
        }
      })}
    </Details>
  );
};
FilterDetails.defaultProps = {
  initiallyOpen: false
};
