import { CheckoutValues } from './CheckoutValues';
import { CheckoutStep, CheckoutStepType } from './CheckoutStep';

export type CalculateCheckoutStep<TCheckoutStep extends string> = {
  (values: CheckoutValues & { [step in TCheckoutStep]?: any }, step?: TCheckoutStep): TCheckoutStep;
};
export type CalculateCheckoutStepFactory = {
  <TCheckoutStep extends string = CheckoutStepType>(stepsOrder: TCheckoutStep[]): CalculateCheckoutStep<TCheckoutStep>;
};
export const calculateCheckoutStepFactory: CalculateCheckoutStepFactory = stepsOrder => (values, step) => {
  const stepFromValues = stepsOrder.find((x: string) => !values[x]) || stepsOrder[stepsOrder.length - 1];

  if (step && step in CheckoutStep) {
    const stepIndex = stepsOrder.findIndex(x => x === step);
    const stepIndexFromValues = stepsOrder.findIndex(x => x === stepFromValues);
    if (stepIndex < stepIndexFromValues) {
      return step;
    }
  }

  return stepFromValues;
};
