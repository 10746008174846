import gql from 'graphql-tag';
import { Mutation, OperationInput } from '@deity/falcon-data';
import { CouponInput } from '@deity/falcon-shop-extension';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';

export const APPLY_COUPON = gql`
  mutation ApplyCoupon($input: CouponInput!) {
    applyCoupon(input: $input)
  }
`;

export type ApplyCouponResult = {
  applyCoupon: boolean;
};

const defaultOptions = {
  mutation: APPLY_COUPON,
  refetchQueries: ['Cart'],
  awaitRefetchQueries: true
};

export class ApplyCouponMutation extends Mutation<ApplyCouponResult, OperationInput<CouponInput>> {
  static defaultProps = defaultOptions;
}

export const useApplyCouponMutation = (
  options: MutationHookOptions<ApplyCouponResult, OperationInput<CouponInput>> = {}
) =>
  useMutation(defaultOptions.mutation, {
    ...defaultOptions,
    ...options
  });
