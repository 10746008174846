import { IValidator } from './IValidator';

const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;

const isValueProvided = x =>
  (x !== '' && x !== undefined && x !== null && x !== false && x !== 'placeholder') ||
  (Array.isArray(x) && (x as any[]).length);

export const requiredValidator: IValidator = ({ value }) => {
  if (!isValueProvided(value)) {
    return { errorI18nId: 'required' };
  }

  return undefined;
};

export function isCheckedValidator(checkedValue = true): IValidator {
  return ({ value }) => {
    if (!isValueProvided(value) || value !== checkedValue) {
      return { errorI18nId: 'isNotChecked' };
    }

    return undefined;
  };
}

export const emailValidator: IValidator = ({ value }) => {
  if (isValueProvided(value) && !VALID_EMAIL_REGEX.test(value.toLowerCase())) {
    return { errorI18nId: 'invalid' };
  }

  return undefined;
};

export const passwordValidator: IValidator = ({ value }) => {
  if (isValueProvided(value) && !VALID_PASSWORD_REGEX.test(value)) {
    return { errorI18nId: 'uncomplexPassword' };
  }

  return undefined;
};

export function lengthValidator(min: number = 0, max?: number): IValidator {
  if (max && max < min) {
    throw new Error(`value of 'min' can not be greater than 'max'!`);
  }

  return ({ value }) => {
    if (isValueProvided(value) && typeof value === 'string') {
      // Ignore newlines when counting characters
      const { length } = value.replace(/\n/g, '');

      if (length < min) {
        return { errorI18nId: 'tooShort', min, ...(max ? { max } : {}) };
      }

      if (max && length > max) {
        return { errorI18nId: 'tooLong', min, max };
      }
    }

    return undefined;
  };
}

export function numLinesValidator(min: number = 0, max?: number): IValidator {
  if (max && max < min) {
    throw new Error(`value of 'min' can not be greater than 'max'!`);
  }

  return ({ value }) => {
    if (isValueProvided(value) && typeof value === 'string') {
      const numLines = value.split('\n').length;

      if (numLines < min) {
        return { errorI18nId: 'tooLittleLines', min };
      }
      if (numLines > max) {
        return { errorI18nId: 'tooManyLines', max };
      }
    }

    return undefined;
  };
}

export const numberValidator: IValidator = ({ value }) => {
  if (isValueProvided(value)) {
    if (value.toString() !== parseFloat(value).toString()) {
      return { errorI18nId: 'notAnNumber' };
    }
  }

  return undefined;
};

export const integerValidator: IValidator = ({ value }) => {
  if (isValueProvided(value)) {
    if (value.toString() !== parseInt(value, 10).toString()) {
      return { errorI18nId: 'notInteger' };
    }
  }

  return undefined;
};

export function rangeValidator(min: number, max: number): IValidator {
  if (max !== undefined && max < min) {
    throw new Error(`value of 'min' can not be greater than 'max'!`);
  }

  return ({ value }) => {
    value = parseInt(value.toString(), 10);

    if (isValueProvided(value) && typeof value === 'number') {
      if (value < min) {
        return { errorI18nId: 'belowRange', min, ...(max ? { max } : {}) };
      }

      if (max && value > max) {
        return { errorI18nId: 'aboveRange', min, max };
      }
    }

    return undefined;
  };
}

export function dateRangeValidator(min?: string, max?: string, dateTimeFormat?: any): IValidator {
  const minDate = min && new Date(min);
  const maxDate = max && new Date(max);

  const formattedMinDate = dateTimeFormat?.(minDate) || minDate.toISOString().split('T')[0];
  const formattedMaxDate = dateTimeFormat?.(maxDate) || maxDate.toISOString().split('T')[0];

  if (maxDate && maxDate < minDate) {
    throw new Error(`value of 'min' can not be earlier than 'max'!`);
  }

  return ({ value }) => {
    if (isValueProvided(value)) {
      const valueDate = new Date(value);

      if (minDate && valueDate < minDate) {
        return { errorI18nId: 'belowDateRange', min: formattedMinDate };
      }

      if (maxDate && valueDate > maxDate) {
        return { errorI18nId: 'aboveDateRange', max: formattedMaxDate };
      }
    }

    return undefined;
  };
}
