import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useIntersectionObserver, canUseIntesectionObserver } from '@deity/falcon-front-kit';
import { Image as FalconImage } from '@deity/falcon-ui';
import { isNativeLoadingAttrSupported } from './useNativeLoadingAttrSupported';

const isServer = typeof document === 'undefined';

const ImagePlaceholderTillMountInnerDOM = ({ src, placeholderSrc, alt, ...rest }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  return <img alt={alt} src={isMounted ? src : placeholderSrc} {...rest} />;
};

const ImageLoadingLazyFallbackInnerDOM = ({ src, placeholderSrc, alt, threshold, rootMargin, useNative, ...rest }) => {
  const [ref, entry] = useIntersectionObserver({ rootMargin, threshold });
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (!isVisible && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
      setVisible(true);
    }
  }, [isVisible, entry.intersectionRatio, entry.isIntersecting]);

  return <img ref={ref} alt={alt} src={isVisible ? src : placeholderSrc} {...rest} />;
};

export const Image = props => {
  if (props.loading !== 'lazy') {
    const { placeholderSrc, threshold, rootMargin, useNative, ...rest } = props;

    return <FalconImage {...rest} />;
  }

  if (isServer || !canUseIntesectionObserver()) {
    const { placeholderSrc, src, threshold, rootMargin, useNative, ...rest } = props;

    return <FalconImage src={placeholderSrc} {...rest} />;
  }

  if (isNativeLoadingAttrSupported && props.useNative) {
    const { threshold, rootMargin, useNative, ...rest } = props;

    return <FalconImage as={ImagePlaceholderTillMountInnerDOM} {...rest} />;
  }

  return <FalconImage as={ImageLoadingLazyFallbackInnerDOM} {...props} />;
};
Image.defaultProps = {
  loading: undefined,
  useNative: false,
  threshold: 0,
  rootMargin: '100px',
  placeholderSrc: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==' // 1px X 1px transparent image
};
