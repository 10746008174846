import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type ScrollToTopProps = {
  ignoreHash: boolean;
};
class ScrollToTopInner extends React.Component<ScrollToTopProps & RouteComponentProps> {
  static defaultProps = {
    ignoreHash: false
  };

  componentDidUpdate(prevProps: RouteComponentProps) {
    const { ignoreHash, location } = this.props;
    const { pathname, hash } = location;
    const { pathname: prevPathname, hash: prevHash } = prevProps.location;

    if (pathname !== prevPathname || (ignoreHash && hash !== prevHash)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children || null;
  }
}

export const ScrollToTop = withRouter(ScrollToTopInner);
