import { useEffect, useState } from 'react';

export const isNativeLoadingAttrSupported = typeof document !== 'undefined' && 'loading' in HTMLImageElement.prototype;

export const useNativeLoadingAttrSupportCheck = () => {
  const [supported, setSupported] = useState<boolean | undefined>(isNativeLoadingAttrSupported);

  useEffect(() => {
    if (supported !== isNativeLoadingAttrSupported) {
      setSupported(isNativeLoadingAttrSupported);
    }
  }, [supported]);

  return supported;
};
