import React, { UIEvent, useEffect, useRef, useState } from 'react';
import { Box, Image, Swipeable, SwipeableItem } from '@deity/falcon-ui';
import { useI18n } from '@deity/falcon-i18n';
import { noScrollbars } from '../helpers';
import { ProductGalleryLayout, productGalleryLayoutArea } from './ProductGalleryLayout';
import { NoProductImagePlaceholder } from './NoProductImagePlaceholder';
import { ProductGalleryThumbLayout } from './ProductGalleryThumbLayout';

export type GalleryItem = {
  full: string;
  thumbnail: string;
  isDefault: boolean;
};
export type ProductGalleryProps = {
  items: GalleryItem[];
};
export const ProductGallery: React.FC<ProductGalleryProps> = ({ items }) => {
  const scrollableElement = useRef(null);
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const { t } = useI18n();

  const scrollToItem = (elementIndex: number) => {
    const container = scrollableElement.current;

    if (container) {
      container.scrollTo({
        left: elementIndex * container.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const container = event.target as Element | null; // needed because event will be nullified after callback

    if (container) {
      window.requestAnimationFrame(() => {
        // Find the item that has the largest visible portion
        const newIndex = Math.floor((items.length * container.scrollLeft) / container.scrollWidth + 0.5);
        setActiveElementIndex(newIndex);
      });
    }
  };

  useEffect(() => {
    // set Default Image to be active
    const defaultItemIndex = items.findIndex(item => item.isDefault);
    if (defaultItemIndex !== -1) {
      scrollToItem(defaultItemIndex);
    }
  }, [items]);

  if (!items.length) {
    return <NoProductImagePlaceholder />;
  }

  if (items.length === 1) {
    return <Image src={items[0].full} alt={t('productGallery.imageAlt')} />;
  }

  return (
    <ProductGalleryLayout>
      <Box gridArea={productGalleryLayoutArea.thumbs}>
        {items.map((item, index) => {
          if (item.thumbnail) {
            return (
              <ProductGalleryThumbLayout
                onClick={() => scrollToItem(index)}
                aria-label={t('productGallery.showImageOf', { index: index + 1, total: items.length })}
                variant={index === activeElementIndex && 'selected'}
                key={item.full}
              >
                <Image key={item.thumbnail} src={item.thumbnail} alt={t('productGallery.imageAlt')} />
              </ProductGalleryThumbLayout>
            );
          }
          return null;
        })}
      </Box>

      <Swipeable
        gridArea={productGalleryLayoutArea.full}
        ref={scrollableElement}
        onScroll={handleScroll}
        alignItems="center"
        css={noScrollbars()}
      >
        {items.map(item => (
          <SwipeableItem key={item.thumbnail} as={Image} src={item.full} alt={t('productGallery.imageAlt')} />
        ))}
      </Swipeable>
    </ProductGalleryLayout>
  );
};
