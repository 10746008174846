import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { OperationInput } from '@deity/falcon-data';

export const SET_CURRENCY = gql`
  mutation SetShopCurrency($currency: String!) {
    setShopCurrency(currency: $currency) {
      shop {
        activeCurrency
      }
    }
  }
`;

export type SetCurrencyResponse = {
  setShopCurrency: {
    shop: {
      activeCurrency: string;
    };
  };
};

export const useSetCurrencyMutation = (
  options: MutationHookOptions<SetCurrencyResponse, OperationInput<{ currency: string }>> = {}
) =>
  useMutation<SetCurrencyResponse, OperationInput<{ currency: string }>>(SET_CURRENCY, {
    refetchQueries: ['BackendConfig', 'Currency', 'Product', 'ProductList', 'Cart', 'MiniCart'],
    awaitRefetchQueries: true,
    ...options
  });
