import React, { useState, useCallback, useLayoutEffect } from 'react';
import { PlaceOrderResult } from '@deity/falcon-shop-extension';
import { useStateCallback } from '../React';
import { CheckoutValues, SetCheckoutValues } from './CheckoutValues';
import { CheckoutStep, CheckoutStepType, CheckoutFlow } from './CheckoutStep';
import { CheckoutContext } from './CheckoutContext';
import { calculateCheckoutStepFactory, CalculateCheckoutStep } from './calculateCheckoutStep';

export type OnStepChanged<TCheckoutStep extends string = CheckoutStepType> = (
  current: TCheckoutStep,
  previous: TCheckoutStep | undefined,
  props: { isForwardNavigation: boolean }
) => void;

interface IGetNextPossibleCheckoutStep<TCheckoutStep> {
  (step: TCheckoutStep): TCheckoutStep | undefined;
}
const getNextPossibleCheckoutStepFactory: <TCheckoutStep extends string = CheckoutStepType>(
  stepsOrder: TCheckoutStep[]
) => IGetNextPossibleCheckoutStep<TCheckoutStep> = stepsOrder => step => {
  const currentStepIndex = stepsOrder.findIndex(x => x === step);

  return currentStepIndex < stepsOrder.length ? stepsOrder[currentStepIndex + 1] : undefined;
};

export type CheckoutProviderProps<TCheckoutStep extends string = CheckoutStepType> = {
  initialValues?: CheckoutValues;
  onValueChanged?: () => void;
  billingSameAsShipping?: boolean;
  step?: TCheckoutStep;
  stepsOrder?: TCheckoutStep[];
  autoStepForward?: boolean;
  onStepChanged?: OnStepChanged;
  calculateStep?: (stepsOrders: TCheckoutStep[]) => CalculateCheckoutStep<TCheckoutStep>;
};
export const CheckoutProvider: React.SFC<CheckoutProviderProps> = props => {
  const {
    initialValues = {},
    billingSameAsShipping,
    stepsOrder,
    autoStepForward,
    onValueChanged,
    onStepChanged
  } = props;

  const calculateStep = useCallback(props.calculateStep(stepsOrder), [stepsOrder]);
  const canSetStep = (values, step) => calculateStep(values, step) === step;
  const [step, setStep] = useStateCallback<keyof typeof CheckoutStep>(
    calculateStep(initialValues, props.step),
    (cStep, pStep) => {
      onStepChanged(cStep, pStep, {
        isForwardNavigation: stepsOrder.findIndex(x => x === cStep) > stepsOrder.findIndex(x => x === pStep)
      });
    }
  );

  const getNextStep = useCallback(getNextPossibleCheckoutStepFactory(stepsOrder), [stepsOrder]);
  const stepForward = () => {
    const next = getNextStep(step);
    setStep(next);

    return next;
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isBillingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(billingSameAsShipping || false);
  const [result, setResult] = useState<PlaceOrderResult>();
  const [values, setValues] = useStateCallback<CheckoutValues>({ ...initialValues }, newValues => {
    onValueChanged();
    if (autoStepForward) {
      setStep(calculateStep(newValues));
    }
  });

  useLayoutEffect(() => {
    if (props.step && props.step !== step) {
      setStep(calculateStep(values, props.step));
    }
  }, [calculateStep, props.step, setStep, step, values]);

  const setEmail: SetCheckoutValues['setEmail'] = email => setValues(x => ({ ...x, email }));

  const setShippingAddress: SetCheckoutValues['setShippingAddress'] = shippingAddress =>
    setValues(x => ({
      ...x,
      shippingAddress,
      billingAddress: isBillingSameAsShipping ? shippingAddress : x.billingAddress,
      shippingMethod: undefined,
      paymentMethod: undefined
    }));

  const setBillingAddress: SetCheckoutValues['setBillingAddress'] = billingAddress =>
    setValues(x => ({
      ...x,
      shippingAddress: isBillingSameAsShipping ? billingAddress : x.shippingAddress,
      billingAddress,
      shippingMethod: isBillingSameAsShipping ? undefined : x.shippingMethod,
      paymentMethod: undefined
    }));

  const setShippingMethod: SetCheckoutValues['setShippingMethod'] = shippingMethod =>
    setValues(x => ({
      ...x,
      shippingMethod,
      paymentMethod: undefined
    }));

  const setPaymentMethod: SetCheckoutValues['setPaymentMethod'] = paymentMethod =>
    setValues(x => ({ ...x, paymentMethod }));

  /**
   * Allows to override OrderData values, useful when order was placed with some overrides and state needs to be synchronized
   * @param order
   */
  const setOrderData: SetCheckoutValues['setOrderData'] = order => {
    if (order) {
      setValues({ ...order });
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        stepsOrder,
        step,
        nextStep: getNextStep(step),
        calculateStep,
        stepForward,
        setStep,
        canSetStep,
        isLoading,
        setLoading,
        isBillingSameAsShipping,
        setBillingSameAsShipping,
        values,
        setEmail,
        setShippingAddress,
        setBillingAddress,
        setShippingMethod,
        setPaymentMethod,
        setOrderData,
        result,
        setResult
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
};
CheckoutProvider.defaultProps = {
  stepsOrder: CheckoutFlow,
  autoStepForward: true,
  billingSameAsShipping: false,
  initialValues: {},
  onValueChanged: () => {},
  onStepChanged: () => {},
  calculateStep: calculateCheckoutStepFactory
};
