import React from 'react';
import { PlaceOrderResult } from '@deity/falcon-shop-extension';
import { CheckoutStepType } from './CheckoutStep';
import { CheckoutValues, SetCheckoutValues } from './CheckoutValues';
import { CalculateCheckoutStep } from './calculateCheckoutStep';

export type CheckoutValuesContext = {
  values: CheckoutValues;
  isBillingSameAsShipping: boolean;
  isLoading: boolean;
  result?: PlaceOrderResult;
};

export type CheckoutNavigationContext<TCheckoutStep extends string = CheckoutStepType> = {
  stepsOrder: TCheckoutStep[];
  step: TCheckoutStep;
  nextStep?: TCheckoutStep;
  calculateStep: CalculateCheckoutStep<TCheckoutStep>;
  stepForward: () => TCheckoutStep;
  canSetStep: (value: CheckoutValues, step: TCheckoutStep) => boolean;
  setStep(step: TCheckoutStep): void;
};

export type CheckoutContextType = CheckoutNavigationContext &
  CheckoutValuesContext &
  SetCheckoutValues & {
    setLoading(isLoading: boolean);
    setBillingSameAsShipping(same: boolean): void;
    setResult(result: PlaceOrderResult): void;
  };

export const CheckoutContext = React.createContext<CheckoutContextType>({} as any);
