import React, { useContext } from 'react';
import { BackendConfigQuery, BackendConfig } from '@deity/falcon-data';
import { ClientConfigQuery } from '../ClientConfig';
import { dateTimeFormatFactory, DateTimeFormatOptions } from './dateTimeFormat';

export type LocaleContextType = {
  locale: string;
  localeFallback: string;
  locales: string[];
  dateTimeFormat: ReturnType<typeof dateTimeFormatFactory>;
};

const LocaleContext = React.createContext<LocaleContextType>({} as any);

const addCimodeLocale = (items: string[]) => {
  if (process.env.NODE_ENV === 'development') {
    if (!items.includes('cimode')) {
      items.unshift('cimode');
    }
  }

  return items;
};

const mergePropsAndConfigs = (
  props: LocaleProviderProps,
  clientConfig: any,
  backendConfig: BackendConfig
): LocaleContextType => {
  const locale = props.locale || backendConfig.activeLocale;
  const localeFallback = props.localeFallback || clientConfig.i18n.fallbackLng;
  const { dateTimeFormatOptions = {} } = props;

  let locales: string[];
  if (props.locales) {
    // eslint-disable-next-line prefer-destructuring
    locales = props.locales;
  } else {
    const { whitelist } = clientConfig.i18n;
    const backendLocales = backendConfig.locales;
    const whitelistedLocales = backendLocales.filter(x => whitelist.some(y => x.startsWith(y)));
    locales = addCimodeLocale(whitelistedLocales);
  }

  const dateTimeFormat = dateTimeFormatFactory([dateTimeFormatOptions.locale, locale, localeFallback], {
    ...dateTimeFormatOptions
  });

  return {
    locale,
    localeFallback,
    locales,
    dateTimeFormat
  };
};

export type LocaleProviderProps = {
  dateTimeFormatOptions?: DateTimeFormatOptions;
  // Allows setting value manually, useful for testing
  // If all three are set, the backend query is skipped
  locale?: LocaleContextType['locale'];
  localeFallback?: LocaleContextType['localeFallback'];
  locales?: LocaleContextType['locales'];
};
export const LocaleProvider: React.SFC<LocaleProviderProps> = props => {
  if (props.locale && props.localeFallback && props.locales) {
    const { dateTimeFormatOptions = {} } = props;
    return (
      <LocaleContext.Provider
        value={{
          locale: props.locale,
          localeFallback: props.localeFallback,
          locales: props.locales,
          dateTimeFormat: dateTimeFormatFactory([dateTimeFormatOptions.locale, props.locale, props.localeFallback], {
            ...dateTimeFormatOptions
          })
        }}
      >
        {props.children}
      </LocaleContext.Provider>
    );
  }

  return (
    <ClientConfigQuery>
      {({ data: { clientConfig } }) => (
        <BackendConfigQuery>
          {({ data: { backendConfig } }) => (
            <LocaleContext.Provider value={mergePropsAndConfigs(props, clientConfig, backendConfig)}>
              {props.children}
            </LocaleContext.Provider>
          )}
        </BackendConfigQuery>
      )}
    </ClientConfigQuery>
  );
};

export const useLocale = (): LocaleContextType => useContext(LocaleContext);

export type LocaleRenderProps = LocaleContextType;
export type LocaleProps = {
  children: (props: LocaleRenderProps) => any;
};

export const Locale: React.SFC<LocaleProps> = ({ children }) => children({ ...useLocale() });
