import React from 'react';
import PropTypes from 'prop-types';
import { Box, ThemingProps } from '@deity/falcon-ui';
import { useI18n } from '@deity/falcon-i18n';
import { Price } from '../Price';

export type ProductPriceProps = {
  regular: number;
  special?: number;
  min?: number;
};
export const ProductPrice: React.SFC<ProductPriceProps & ThemingProps> = ({ regular, special, min, ...rest }) => {
  const { t } = useI18n();
  if (min) {
    return (
      <Box display="flex" alignItems="center">
        {t('price.from')} <Price ml="xs" value={min} {...rest} />
      </Box>
    );
  }
  return special ? (
    <>
      <Price value={regular} fontSize="md" {...rest} variant="old" mr="xs" />
      <Price value={special} fontSize="md" {...rest} variant="special" />
    </>
  ) : (
    <Price value={regular} fontSize="md" {...rest} />
  );
};
ProductPrice.propTypes = {
  regular: PropTypes.number.isRequired,
  special: PropTypes.number
};
