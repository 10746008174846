import React from 'react';
import { Select, ThemingProps } from '@deity/falcon-ui';

export type SelectInputOption = {
  value: string | number;
  label: string;
};

const OptionList = React.memo<{ options: SelectInputOption[] }>(({ options }) => (
  <>
    {options.map(x => (
      <option key={`${x.value}-${x.label}`} value={x.value}>
        {x.label}
      </option>
    ))}
  </>
));

export type SelectInputProps = ThemingProps &
  React.InputHTMLAttributes<HTMLSelectElement> & {
    options: SelectInputOption[];
  };
export const SelectInput: React.SFC<SelectInputProps> = React.memo(({ options, ...rest }) => {
  return (
    <Select {...rest}>
      <OptionList options={options} />
    </Select>
  );
});
