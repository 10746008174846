import React, { useState, useCallback, useRef, KeyboardEvent, useEffect, useContext } from 'react';
import { themed } from '../theme';
import { Box } from './Box';
import { Icon } from './Icon';

const getHtmlChildren = (el: HTMLElement) =>
  Array.from(el.children).filter(child => child instanceof HTMLElement) as HTMLElement[];

type DropdownPropsType = {
  onChange?: (menuItemValue: any) => void;
  children?: React.ReactNode;
  id?: string;
};

type DropdownContextType = {
  open?: boolean;
  setOpen?: (open: boolean | ((curOpen: boolean) => boolean)) => void;
  onChange?: (menuItemValue: any) => void;
  menu?: React.MutableRefObject<null | HTMLDivElement>;
  label?: React.MutableRefObject<null | HTMLDivElement>;
  id?: string;
};

const DropdownContext = React.createContext<DropdownContextType>({});

const DropdownInnerDOM: React.FC<DropdownPropsType> = ({ children, onChange, id, ...rest }) => {
  const [open, setOpen] = useState(false);

  const onChangeAndClose = (value: any) => {
    if (onChange) onChange(value);
    setOpen(false);
  };

  const menu = useRef<HTMLDivElement>(null);
  const label = useRef<HTMLDivElement>(null);

  const [selectedIndex, selectIndex] = useState(-1);
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (menu.current) {
      const options = getHtmlChildren(menu.current);
      let preventDefault = true;
      switch (event.keyCode) {
        // up
        case 38: {
          selectIndex(s => Math.max(0, s - 1));
          break;
        }
        // down
        case 40: {
          selectIndex(s => Math.min(s + 1, options.length));
          break;
        }
        // escape
        case 27: {
          setOpen(false);
          if (label.current) label.current.focus();
          break;
        }
        default: {
          preventDefault = false;
        }
      }

      if (preventDefault) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }, []);

  useEffect(() => {
    if (menu.current) {
      const options = getHtmlChildren(menu.current);
      const selected = options[selectedIndex];
      if (selected) {
        const selectedButton = selected.getElementsByTagName('button')[0];
        if (selectedButton) selectedButton.focus();
      }
    }
  }, [selectedIndex]);
  useEffect(() => {
    if (!open) selectIndex(-1);
  }, [open]);

  return (
    <DropdownContext.Provider value={{ open, setOpen, onChange: onChangeAndClose, menu, label, id }}>
      <Box
        id={id}
        aria-labelledby={id && `${id}-label`}
        onBlur={event => {
          // Only trigger close if no inner element has focus either; https://stackoverflow.com/a/47563344/8521718
          if (!event.currentTarget.contains(event.relatedTarget as any)) setOpen(false);
        }}
        onKeyDown={onKeyDown}
        aria-haspopup="listbox"
        position="relative"
        {...rest}
      >
        {children}
      </Box>
    </DropdownContext.Provider>
  );
};

export const Dropdown = themed({
  tag: DropdownInnerDOM,

  defaultTheme: {
    dropdown: {
      display: 'flex',
      borderRadius: 'medium',
      border: 'regular',
      borderColor: 'secondaryDark',

      css: ({ theme }) => ({
        userSelect: 'none',
        position: 'relative',
        ':hover, :focus': {
          borderColor: theme.colors.primary
        }
      })
    }
  }
});

const DropdownLabelInnerDOM: React.FC<any> = ({ children, ...rest }) => {
  const { open, id, setOpen, label } = useContext(DropdownContext);
  const toggle = useCallback(() => {
    setOpen(exp => !exp);
  }, [setOpen]);
  return (
    <Box as="button" id={id && `${id}-label`} onClick={toggle} ref={label} {...rest}>
      <Box flex="1">{children}</Box>
      <Icon src={open ? 'dropdownArrowUp' : 'dropdownArrowDown'} fallback={open ? '▴' : '▾'} />
    </Box>
  );
};

export const DropdownLabel = themed({
  tag: DropdownLabelInnerDOM,

  defaultTheme: {
    dropdownLabel: {
      display: 'flex',
      py: 'xs',
      px: 'sm',
      fontSize: 'sm',
      justifyContent: 'space-between',
      css: {
        width: '100%',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        color: 'inherit'
      }
    }
  }
});

const DropdownMenuInnerDOM: React.FC<any> = props => {
  const { open, id, menu } = useContext(DropdownContext);
  return open ? (
    <Box
      as="ul"
      ref={menu}
      aria-controls={id}
      aria-labelledby={id && `${id}-label`}
      role="listbox"
      tabIndex={-1}
      display={open ? 'block' : 'none'}
      {...props}
    />
  ) : null;
};

export const DropdownMenu = themed({
  tag: DropdownMenuInnerDOM,

  defaultTheme: {
    dropdownMenu: {
      m: 'none',
      p: 'none',
      borderRadius: 'medium',
      boxShadow: 'subtle',
      bg: 'white',
      css: ({ theme }) => ({
        position: 'absolute',
        listStyle: 'none',
        top: 'calc(100% + 1px)',
        left: 0,
        right: 0,
        zIndex: theme.zIndex.dropDownMenu
      }),

      variants: {
        above: {
          css: {
            top: 'auto',
            bottom: 'calc(100% + 1px)'
          }
        }
      }
    }
  }
});

const DropdownMenuItemInnerDOM: React.FC<any> = ({ children, ...props }) => {
  const { onChange, label } = useContext(DropdownContext);
  const select = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onChange) onChange(props.value);
      if (e) e.stopPropagation();
      if (label) label.current.focus();
    },
    [onChange, props.value, label]
  );

  return (
    <Box as="li" role="option" {...props}>
      <Box as="button" tabIndex={-1} onClick={select}>
        {children}
      </Box>
    </Box>
  );
};

export const DropdownMenuItem = themed({
  tag: DropdownMenuItemInnerDOM,

  defaultProps: {
    value: undefined as any
  },

  defaultTheme: {
    dropdownMenuItem: {
      css: ({ theme }) => ({
        background: 'orange',
        '> button': {
          width: '100%',
          padding: theme.spacing.xs,
          textAlign: 'left',
          border: 'none',
          background: 'none',
          color: 'inherit',
          cursor: 'pointer'
        },
        ':hover, :focus': {
          background: `${theme.colors.primaryLight}`
        }
      })
    }
  }
});
