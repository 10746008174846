import { useState } from 'react';

/**
 * React Hook which allows throwing an error (catchable via ErrorBoundary) from another hook or promise
 * @see https://github.com/facebook/react/issues/14981
 */
export function useThrowError() {
  const [, setError] = useState<Error>();

  const throwError = (error: Error) =>
    setError(() => {
      throw error;
    });

  return throwError;
}
