/**
 * Helper to get the currency label including symbol e.g. $ USD
 * @param {string} currency currency code e.g. USD
 * @param {string} locale lacel code e.g. en or en-GB
 * @returns {string} currency label
 */
export function getCurrencyLabel(currency: string, locale: string): string {
  const localeFormatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency'
  });

  const localeData = localeFormatter.formatToParts();
  const currencyArray = localeData.filter(part => part.type === 'currency');

  // For some locales the currency code is the same as the currency symbol. In this case we only return the code
  if (currencyArray.length && currencyArray[0].value !== currency) {
    return `${currencyArray[0].value} ${currency}`;
  }

  return currency;
}
