import { useEffect, useRef } from 'react';

export const useMounted = (): { current: boolean } => {
  const isMounted = useRef(false);

  // Changing a ref like this doesn't cause rerenders
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
