import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_CURRENCIES = gql`
  query BackendConfig {
    backendConfig {
      shop {
        activeCurrency
        baseCurrency
        currencies
      }
    }
  }
`;

export type CurrencyResponse = {
  backendConfig: {
    shop: {
      activeCurrency: string;
      baseCurrency: string;
      currencies: string[];
    };
  };
};

export class CurrencyQuery extends Query<CurrencyResponse> {
  static defaultProps = {
    query: GET_CURRENCIES
  };
}
