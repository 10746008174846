import { codes } from './codes';

// FalconError is simplified version of ApolloError
// that does not import whole `graphql` package resulting in much smaller bundle size
// https://github.com/apollographql/apollo-server/blob/master/packages/apollo-server-errors/src/index.ts
export class FalconError extends Error {
  constructor(message, code) {
    super(message);
    // if no name provided, use the default. defineProperty ensures that it stays non-enumerable
    if (!this.name) {
      Object.defineProperty(this, 'name', { value: 'FalconError' });
    }

    if (code) {
      Object.defineProperty(this, 'extensions', { value: { code } });
    }
  }
}

export class AuthenticationError extends FalconError {
  /**
   * @param {string} message
   * @param {string | undefined} code optional error code, default is `UNAUTHENTICATED`
   */
  constructor(message, code) {
    super(message, code || codes.UNAUTHENTICATED);
    Object.defineProperty(this, 'name', { value: 'AuthenticationError' });
  }
}

export class ForbiddenError extends FalconError {
  /**
   * @param {string} [message]
   */
  constructor(message = 'Access is forbidden') {
    super(message, codes.FORBIDDEN);
    Object.defineProperty(this, 'name', { value: 'ForbiddenError' });
  }
}

export class EntityNotFoundError extends FalconError {
  /**
   * @param {string} [message]
   */
  constructor(message = 'Entity not found') {
    super(message, codes.NOT_FOUND);
    Object.defineProperty(this, 'name', { value: 'EntityNotFoundError' });
  }
}

/**
 * @example
 * ```javascript
 *  throw new UserInputError(e.message, {
 *    errors: {
 *      email: { code: 'email_already_taken', message: e.message }
 *    }
 *  });
 * ```
 */
export class UserInputError extends FalconError {
  /**
   * @param {string} message
   * @param {object} [properties]
   */
  constructor(message, properties) {
    super(message, codes.BAD_USER_INPUT, properties);

    Object.defineProperty(this, 'name', { value: 'UserInputError' });
  }
}
