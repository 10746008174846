import React, { useCallback } from 'react';
import { Field as FormikField, FastField as FormikFastField, FieldProps as FormikFieldProps, getIn } from 'formik';
import { useI18n } from '@deity/falcon-i18n';
import { useFormContext } from './FormContext';
import { fieldValidator, IValidator } from './IValidator';

const translateIfExists = (t, key?: string) => (key ? (t(key, { defaultValue: '' }) as string) : undefined);

const LABEL_SUFFIX = 'FieldLabel';
const PLACEHOLDER_SUFFIX = 'FieldPlaceholder';

export const getLabelI18nId = (formI18nId: string, fieldName: string) => `${formI18nId}.${fieldName}${LABEL_SUFFIX}`;
export const getPlaceholderI18nId = (formI18nId: string, fieldName: string) =>
  `${formI18nId}.${fieldName}${PLACEHOLDER_SUFFIX}`;

export type FieldRenderProps<TValue = any> = {
  form: FormikFieldProps<TValue>['form'] & {
    id?: number | string;
  };
  field: FormikFieldProps<TValue>['field'] & {
    id?: string;
    placeholder?: string;
    invalid: boolean;
  };
  label?: string;
  error?: string;
  i18nIds: {
    label?: string;
    placeholder?: string;
  };
};

export type FieldProps<TValue = any> = {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  validate?: IValidator[];
  fast?: boolean;
  children?: (props: FieldRenderProps<TValue>) => React.ReactNode;
};
export const Field: React.SFC<FieldProps> = React.memo(props => {
  const { name, label, placeholder, validate, children, fast, ...restProps } = props;
  const { t } = useI18n();
  const { id: formId, i18nId: formI18nId } = useFormContext();

  if (!children) {
    return null;
  }

  const i18nIds = formI18nId
    ? {
        label: getLabelI18nId(formI18nId, name),
        placeholder: getPlaceholderI18nId(formI18nId, name)
      }
    : {};

  const fieldLabel = label || translateIfExists(t, i18nIds.label);
  const fieldPlaceholder = placeholder || translateIfExists(t, i18nIds.placeholder);

  const validateCallback = useCallback(() => fieldValidator(validate, { name, label: fieldLabel, formI18nId, t }), [
    validate,
    name,
    fieldLabel,
    formI18nId,
    t
  ]);

  const FieldComponent = fast ? FormikFastField : FormikField;
  return (
    <FieldComponent name={name} validate={validateCallback()}>
      {({ form, field, meta: { touched, error } }: FormikFieldProps) =>
        children({
          form: { ...form, id: formId },
          field: {
            id: `${formId}-${name}`,
            ...restProps,
            ...field,
            placeholder: fieldPlaceholder,
            invalid: !!touched && !!error
          },
          error,
          label: fieldLabel,
          i18nIds
        })
      }
    </FieldComponent>
  );
});
